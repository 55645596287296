<template>
  <div class="box">
    <div id="manufacturerCard">
      <div class="title">
        <div class="manufacturer-name">
          <div id="back" class="fa fa-angle-left" @click="goBack"></div>
          <span class="name">{{ manufacturer.name }}</span>
          <span class="is-deleted" v-if="manufacturer.status === 'DELETED'"> {{ $t("singleManufacturer.deleted") }}</span>
        </div>
        <div class="manufacturer-tools">
          <div class="fa fa-sign-out" v-if="isRoleAdmin && manufacturer.status !== 'DELETED'" @click="downloadFile">
            <span class="exporthint">{{ $t("singleManufacturer.exportHint") }}</span>
          </div>
          <div class="fa fa-clone" v-if="isRoleAdmin" @click="cloneManufacturer">
            <span class="clonehint">{{ $t("singleManufacturer.cloneHint") }}</span>
          </div>
          <div class="fa fa-trash-o" v-if="isRoleAdmin && manufacturer.status !== 'DELETED'" @click="deleteManufacturer">
            <span class="deletehint">{{ $t("singleManufacturer.deleteHint") }}</span>
          </div>
          <div class="fa fa-arrow-circle-o-up" v-else-if="isRoleAdmin" @click="restoreManufacturer">
            <span class="restorehint">{{ $t("singleManufacturer.restoreHint") }}</span>
          </div>
          <span class="line">|</span>
          <span id="label" class="data">{{ $t("singleManufacturer.lastUpdate") }}: </span>
          <span id="format" class="data">{{ this.manufacturer.updated }}</span>

          <button @click="show" id="logsButton" class="btn waves-effect waves-light" v-if="isRoleAdmin">Logs</button>

          <div v-show="this.showLogs">
            <LogMenu2 v-bind:historyLogs="historyLogs"/>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="body-l">
          <form autocomplete="off">
            <div class="input-field col">
              <input id="name" type="text" class="changeable" disabled v-model="manufacturer.name">
              <label for="name" class="active">{{ $t("singleManufacturer.name") }}</label>
            </div>
            <div class="input-field col">
              <input id="createTime" type="text" disabled v-model="manufacturer.created">
              <label for="createTime" class="active">{{ $t("singleManufacturer.created") }}</label>
            </div>
            <div class="input-field col">
              <input id="updateTime" type="text" disabled v-model="manufacturer.updated">
              <label for="updateTime" class="active">{{ $t("singleManufacturer.updated") }}</label>
            </div>
          </form>
        </div>
        <div class="body-r">
          <form autocomplete="off">
            <div class="input-field col">
              <input id="firmName" type="text" class="changeable" disabled v-model="manufacturer.firmName">
              <label for="firmName" class="active">{{ $t("singleManufacturer.firmName") }}</label>
            </div>
            <div class="input-field col">
              <input id="address" type="text" class="changeable" disabled v-model="manufacturer.address">
              <label for="address" class="active">{{ $t("singleManufacturer.address") }}</label>
            </div>
            <div class="input-field col">
              <input id="phone" type="text" class="changeable" disabled v-model="manufacturer.phone">
              <label for="phone" class="active">{{ $t("singleManufacturer.phone") }}</label>
            </div>
            <div class="input-field col">
              <input id="email" type="text" class="changeable" disabled v-model="manufacturer.email">
              <label for="email" class="active">{{ $t("singleManufacturer.email") }}</label>
            </div>
            <button type="button" id="edit"
                    class="btn waves-effect waves-light"
                    v-if="isRoleAdmin && manufacturer.status !== 'DELETED'"
                    @click="editItemFields()">
              {{ $t("singleManufacturer.edit") }}<i class="fa fa-pencil right"></i>
            </button>
            <button type="button" id="submit-edit" class="btn waves-effect waves-light" @click="editRequest()">
              {{ $t("singleManufacturer.submit") }}<i class="fa fa-check right"></i>
            </button>
            <button type="button" id="reset" class="btn waves-effect waves-light" @click="resetFields">
              {{ $t("singleTicket.reset") }}<i class="material-icons right">cancel</i>
            </button>

          </form>
        </div>
      </div>
    </div>
    <ItemsByManufacturerId :manufacturer-id="id" :key="updateKey"/>
  </div>
</template>

<script>
import $ from 'jquery'
import axios from "axios";
import moment from 'moment';
import Vue from "vue";
import VueToast from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-default.css';
import Link from "../utils/Link";
import ItemsByManufacturerId from "../item/ItemsByManufacturerId.vue";
import VueConfirmDialog from "vue-confirm-dialog";
import LogMenu2 from "../historyLog/LogMenu2";
import App from "../../App";
import ManufacturersBoard from "../manufacturer/ManufacturersBoard";
import SideBarMenu from "@/components/SideBarMenu.vue";

Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)

export default {
  props: {
    id: {
      type: String,
      default: null,
    }
  },

  components: {
    ItemsByManufacturerId: ItemsByManufacturerId,
    LogMenu2
  },

  data() {
    return {
      manufacturer: {},
      historyLogs: [],
      showLogs: false,
      updateKey: -1,
      isRoleAdmin: false
    };
  },

  mounted() {
    this.isRoleAdmin = SideBarMenu.methods.isRoleAdmin();
    this.getSingleManufacturer();
  },

  methods: {
    moment,

    show() {
      this.showLogs = !this.showLogs;
    },

    downloadFile() {
      const headers = Link.methods.getHeaders();
      axios.get(
        Link.methods.getExportCsvUrl("manufacturer"),
        {headers}
      ).then(res => {
        let filename = "manufacturers.csv"
        let text = res.data;
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        document.body.removeChild(element);
      })
    },

    getLogs() {
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getManufacturerLogs(this.id),
        {headers}
      ).then(res => {
        this.historyLogs = res.data;
        for (let i = 0; i < this.historyLogs.length; i++) {
          this.historyLogs[i].message = App.methods.getLink(this.historyLogs[i].message);
        }
      }).catch(reason => {
        if (!reason.toString().includes("404")) {
          this.createToast(this.$t('mainLog.logsNotFound'), "success")
        }
      })
    },

    getSingleManufacturer() {
      const id = this.id;
      const headers = Link.methods.getHeaders();
      return axios.get(
        Link.methods.getManufacturerById(id),
        {headers}
      ).then((res) => {
        res.data.created = moment(res.data.created).format('DD.MM.YYYY HH:mm');
        res.data.updated = moment(res.data.updated).format('DD.MM.YYYY HH:mm');
        this.manufacturer = res.data
        return this.getLogs();
      }).catch(() => {
        this.createToast(this.$t("singleManufacturer.manufacturerNotFound", [id]), "error")
      });
    },

    sendItemEditRequest() {
      const headers = Link.methods.getHeaders();
      this.manufacturer.created = "";
      this.manufacturer.updated = "";
      return axios.put(
          Link.methods.getManufacturerUpdate(),
          this.manufacturer,
          {headers}
      ).catch((reason) => {
        this.createToast(
            this.$t("singleManufacturer.manufacturerNotEdited", [this.manufacturer.name, reason]),
            "error"
        )
      });
    },

    editItemFields() {
      //$('#upload-img').css('display', 'block');
      $('.changeable').prop('disabled', false);
      $('#edit').css('display', 'none');
      $('#submit-edit').css('display', 'block');
      $('#reset').css('display', 'block');
    },

    disableFields() {
      $('.changeable').prop('disabled', true);
      $('#edit').css('display', 'block');
      $('#submit-edit').css('display', 'none');
      $('#reset').css('display', 'none');
    },

    editRequest() {
      this.sendItemEditRequest(
          this.id
      ).then(() => {
        this.createToast(this.$t("singleManufacturer.edited", [this.manufacturer.name]), "success");
        this.getSingleManufacturer();
      });
      this.disableFields()
    },

    resetFields() {
      this.getSingleManufacturer();
      this.disableFields();
    },

    goBack() {
      return this.$router.back();
    },

    cloneManufacturer() {
      let headers = Link.methods.getHeaders();
      ManufacturersBoard.methods.getManufacturers().then( (manufacturers) => {
        const orig_name = this.manufacturer.name;
        let attempt = 1;
        let new_name = orig_name;

        while (manufacturers.find((x) => x.name === new_name)) {
          attempt += 1;
          new_name = orig_name + " (" + attempt + ")"
        }
        this.manufacturer.name = new_name;
        this.manufacturer.created = undefined;
        this.manufacturer.updated = undefined;

        axios.post(
            Link.methods.getManufacturesCreateUrl(),
            this.manufacturer,
            {headers}
        ).then((res) => {
            const id = res.data.id;
            this.createToast(this.$t("singleManufacturer.cloneToast", [this.manufacturer.name]), "success")
            this.$router.replace({name: 'manufacturerById', params: {"id": id.toString()}});
            this.manufacturer.id = id;
            this.updateKey = id;
            this.manufacturer.status = res.data.status;
            setTimeout(() => {
              this.editItemFields();
            });
        }).catch((reason) => {
            console.error(reason);
            this.createToast(
                this.$t("singleManufacturer.manufacturerNotCloned", [this.manufacturer.name, reason.toString()]),
                "error"
            );
        });
      }).catch( (reason) => {
        this.createToast(
            this.$t("singleManufacturer.manufacturerNotCloned", [this.manufacturer.name, reason.toString()]),
            "error"
        )
      })
    },

    deleteManufacturer() {
      this.$confirm(
        {
          message: this.$t("singleManufacturer.deleteMessage", [this.manufacturer.name]),
          button: {
            no: this.$t("singleManufacturer.confirmNo"),
            yes: this.$t("singleManufacturer.confirmYes")
          },
          callback: confirm => {
            if (confirm) {
              let headers = Link.methods.getHeaders();
              axios.delete(
                  Link.methods.getManufacturerDeleteUrl(this.id),
                  {headers}
              ).then(() => {
                   this.goBack();
              }).catch((reason) => {
                   this.createToast(
                       this.$t("singleManufacturer.manufacturerWasNotDeleted", [this.manufacturer.name, reason]),
                       "error"
                   )
              })
            }
          }
        }
      )
    },

    restoreManufacturer() {
      this.$confirm(
        {
          message: this.$t("singleManufacturer.restoreMessage", [this.manufacturer.name]),
          button: {
            no: this.$t("singleManufacturer.confirmNo"),
            yes: this.$t("singleManufacturer.confirmYes")
          },
          callback: confirm => {
            if (confirm) {
              let headers = Link.methods.getHeaders();
              axios.delete(
                  Link.methods.getManufacturerRestoreUrl(this.id),
                  {headers}
              ).then(() => {
                  this.manufacturer.status = 'ACTIVE';
                  // this.goBack();
              }).catch((reason) => {
                  this.createToast(
                      this.$t("singleManufacturer.manufacturerWasNotRestored", [this.manufacturer.name, reason]),
                      "error"
                  )
              })
            }
          }
        }
      )
    },

    createToast(msg, type) {
      Vue.use(VueToast);
      Vue.$toast.open({
        type: type,
        message: msg,
        position: "bottom-right",
        duration: 5000
      });
    }
  },
}
</script>


<style scoped lang="scss">
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import '../../../public/materialize-src/sass/materialize.scss';
@import "../../../public/styles/hints";

.box {
  border-radius: 4px;
}

#back {
  color: #3283B0;
}

.title {
  display: flex;
  grid-template-areas:
        "ab ab ac ac"
        "ab ab ac ac";
  margin: auto;
  grid-area: a;
}

.fa-clone, .fa-trash-o, .fa-arrow-circle-o-up, .fa-sign-out, .fa-angle-left {
  color: #535353;
  padding: 15px;
}

.exporthint, .clonehint, .tickethint, .deletehint, .restorehint {
  bottom: 80%;
  left: 10%;
}

.manufacturer-name, .manufacturer-tools {
  margin-top: 26px;
  margin-bottom: 20px;
  width: 50%;
}

.manufacturer-name {
  float: left;
  margin-left: 20px;
  margin-right: 6px;
  font-size: 22px;
  grid-area: ab;
}

.manufacturer-tools {
  text-align-last: right;
  float: right;
  margin-right: 36px;
  font-size: 18px;
  grid-area: ac;
}

#manufacturerCard {
  margin-bottom: 20px;
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.body-l, .body-r {
  display: inline-block;
  height: 300px;
  width: 45%;
  border-radius: 15px;
  background-color: rgba(77, 77, 77, 0.03);
  padding: 5px;
}

.body-l {
  height: 170px;
}

.body-r {
  height: 280px;
}

#edit {
  width: 100%;
  background: #535353;
}

#reset, #submit-edit {
  width: -webkit-fill-available;
  display: none;
  background: #535353;
}

#submit-edit {
  margin-bottom: 15px;
}

button {
  border-radius: 4px;
  box-sizing: revert;
}

.prefix.active {
  color: #EB2227 !important;
}

input:not(.browser-default):focus:not([readonly]) {
  border-bottom: 1px solid #EB2227 !important;
}

input:not(.browser-default):focus:not([readonly]) + label {
  color: #EB2227 !important;
}

#logsButton {
  margin-left: 10px;
  background: #535353;
}

.is-deleted {
  color: red;
  font-weight: bold;
}

</style>